import React, { Component } from "react"
import axios from "axios"
import Helmet from "react-helmet"

export default class Contact extends Component {
  state = {
    userName: "",
    companyName: "",
    industryType: "",
    designation: "",
    email: "",
    phone: "",
    formSuccesMessage: "",
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmitHandler = e => {
    e.preventDefault()
    axios
      .put("https://contact-form-data.herokuapp.com/contact", this.state)
      .then(response => {
        console.log("response", response)
        this.setState({
          formSuccesMessage:
            "Thank you for reaching out to us. We shall revert to you soon.",
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    const {
      userName,
      companyName,
      industryType,
      designation,
      email,
      phone,
      formSuccesMessage,
    } = this.state
    let formSuccesMessageClass

    if (formSuccesMessage) {
      formSuccesMessageClass = "border border-success"
    }

    return (
      <div
        className="w-100"
        id="crmWebToEntityForm"
        dangerouslySetInnerHTML={{
          __html: `
          <div id='crmWebToEntityForm' style='width:100%;margin:auto;'>
          <META HTTP-EQUIV='content-type' CONTENT='text/html;charset=UTF-8'>
          <form action='https://crm.zoho.com/crm/WebForm' name=WebForm3202602000000659031 method='POST'
             onSubmit='javascript:document.charset="UTF-8"; return checkMandatory3202602000000659031()'
             accept-charset='UTF-8'>
             <input type='text' style='display:none;' name='xnQsjsdp'
                value='8d056fb0597db730a90f0cdd8c2a5c3264f8ceab04bc0e212557d63ce2a4b9c2'></input>
             <input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
             <input type='text' style='display:none;' name='xmIwtLD'
                value='98f6270aa7879c3e8cb0572ce163b2198e5f1c9dca05e51a67ecd3742e22e3d6'></input>
             <input type='text' style='display:none;' name='actionType' value='Q3VzdG9tTW9kdWxlMQ=='></input>
             <input type='text' style='display:none;' name='returnURL' value='https://machstatz.com'> </input><br></br>
             <!-- Do not remove this code. -->
             <div class="floating-label">
                <input type='text' class="floating-input" maxlength='255'
                   name='COBJ1CF8' autocomplete="off" placeholder=" "/>
                <span class="highlight"></span>
                <label>Your Name<span style=color:black>*</span></label>
             </div>
             <div class="floating-label">
                <input type='text' class="floating-input" maxlength='255'
                   name='Email' autocomplete="off" placeholder=" "/>
                   <span class="highlight"></span>
                   <label>Official Email<span style=color:black>*</span></label>
             </div>
             <div class="floating-label">
    
                <input type='text' class="floating-input" maxlength='255'
                   name='COBJ1CF9' autocomplete="off" placeholder=" "/>
                   <span class="highlight"></span>
                   <label>Your Phone Number</label>
             </div>
             <div class="floating-label">
                <input type='text' class="floating-input" maxlength='120'
                   name='NAME' autocomplete="off" placeholder=" "/>
                   <span class="highlight"></span>
                   <label>Company Name<span style=color:black>*</span></label>
             </div>
             <div class="floating-label">
              <select class="floating-select" name='COBJ1CF21' onclick="this.setAttribute('value', this.value);" value="None">
                  
              <option value="" disabled selected><label>Contacting us for?<span style=color:red>*</span></label></option>
                  <option value='Demonstration&#x20;of&#x20;what&#x20;we&#x20;do'>Demonstration of what we do
                  </option>
                  <option value='Business&#x20;Opportunity&#x2f;Partnership'>Business Opportunity&#x2f;Partnership
                  </option>
                  <option value='Job&#x20;Seeker'>Job Seeker</option>
                  <option value='Others'>Others</option>
                  </select>
                  <span class="highlight"></span>
                  
             </div>
             <div class="floating-label">
                <input type='text' class="floating-input" maxlength='255'
                   name='COBJ1CF5' autocomplete="off" placeholder=" "/>
                   <span class="highlight"></span>
                   <label>Your Message<span style=color:black>*</span></label>
             </div>
           
            <button type="submit" class="btn request-button requestButton">
              Submit
              </button>
             <script>
                var mndFileds = new Array('NAME', 'COBJ1CF13', 'COBJ1CF8', 'COBJ1CF5', 'COBJ1CF21');
                var fldLangVal = new Array('Company Name', 'Email ID', 'Name', 'Your Message', 'Contacting us for?');
                var name = '';
                var email = '';
    
                function checkMandatory3202602000000659031() {
                   for (i = 0; i < mndFileds.length; i++) {
                      var fieldObj = document.forms['WebForm3202602000000659031'][mndFileds[i]];
                      if (fieldObj) {
                         if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length == 0) {
                            if (fieldObj.type == 'file') {
                               alert('Please select a file to upload.');
                               fieldObj.focus();
                               return false;
                            }
                            alert(fldLangVal[i] + ' cannot be empty.');
                            fieldObj.focus();
                            return false;
                         } else if (fieldObj.nodeName == 'SELECT') {
                            if (fieldObj.options[fieldObj.selectedIndex].value == '-None-') {
                               alert(fldLangVal[i] + ' cannot be none.');
                               fieldObj.focus();
                               return false;
                            }
                         } else if (fieldObj.type == 'checkbox') {
                            if (fieldObj.checked == false) {
                               alert('Please accept  ' + fldLangVal[i]);
                               fieldObj.focus();
                               return false;
                            }
                         }
                         try {
                            if (fieldObj.name == 'Last Name') {
                               name = fieldObj.value;
                            }
                         } catch (e) { }
                      }
                   }
                   document.getElementById('formsubmit').disabled = true;
                }
             </script>
             <input type='hidden' name='eo' value='14f4ec16431e0686150daa43f3210513' /> <input type='hidden' name='te'
                value='true' /><img id='tr_img_98f6270aa7879c3e8cb0572ce163b2198e5f1c9dca05e51a67ecd3742e22e3d6'
                src='https://crm.zohopublic.com/crm/WebFormAnalyticsServlet?rid=98f6270aa7879c3e8cb0572ce163b2198e5f1c9dca05e51a67ecd3742e22e3d6gid8d056fb0597db730a90f0cdd8c2a5c3264f8ceab04bc0e212557d63ce2a4b9c2gida378f81585080d3b6fb1acbec6638b71gid0553f79ba9ccf83e1be97f6851b4205dc78530f131c55496a36cdc928fa214e9gid14f4ec16431e0686150daa43f3210513'
                style='display: none;' />
             <script> if (typeof wfTRKey == 'undefined') { wfTRKey = {}; } wfTRKey['url_98f6270aa7879c3e8cb0572ce163b2198e5f1c9dca05e51a67ecd3742e22e3d6'] = 'https://crm.zohopublic.com/crm/WebFormAnalyticsServlet?rid=98f6270aa7879c3e8cb0572ce163b2198e5f1c9dca05e51a67ecd3742e22e3d6gid8d056fb0597db730a90f0cdd8c2a5c3264f8ceab04bc0e212557d63ce2a4b9c2gid364f49863aa1c6f080988c8e111a90b8gid0553f79ba9ccf83e1be97f6851b4205dc78530f131c55496a36cdc928fa214e9gid14f4ec16431e0686150daa43f3210513'; wfTRKey['rs_98f6270aa7879c3e8cb0572ce163b2198e5f1c9dca05e51a67ecd3742e22e3d6'] = false; function sf(ev) { var te = ev.target ? ev.target : ev.srcElement; var nd = te.nodeName ? te.nodeName.toLowerCase() : ''; var fm; if (te.form) { fm = te.form.elements.xmIwtLD.value; } if (fm && !wfTRKey['rs_' + fm] && ((nd == 'input' && te.type != 'file') || (nd == 'input' && te.type == 'file' && ev.type == 'click') || nd == 'select' || nd == 'textarea' || nd == 'div')) { document.getElementById('tr_img_' + fm).setAttribute('src', wfTRKey['url_' + fm]); wfTRKey['rs_' + fm] = true } } if (document.addEventListener) { document.addEventListener('click', sf); document.addEventListener('change', sf); } else { document.attachEvent('onclick', sf); document.attachEvent('onchange', sf); }</script>
          </form>
       </div>
      `,
        }}
      >
        {/* <form className="w-100" onSubmit={this.onSubmitHandler}>
          <div className="position-relative">
            <input
              type="text"
              className="custom-input"
              name="userName"
              id="userName"
              value={userName}
              onChange={this.changeHandler}
              autocomplete="off"
              required
            />
            <span className="floating-label">
              Your Name
                <span style={{ color: 'black' }}>*</span>
            </span>
          </div>
          <div className="position-relative">
            <input
              type="text"
              className="custom-input"
              name="companyName"
              id="companyName"
              value={companyName}
              onChange={this.changeHandler}
              autocomplete="off"
              required
            />
            <span className="floating-label">
              Company Name
                <span style={{ color: 'black' }}>*</span>
            </span>
          </div>
          <div className="position-relative">
            <input
              type="text"
              className="custom-input"
              name="industryType"
              id="industryType"
              value={industryType}
              onChange={this.changeHandler}
              autocomplete="off"
              required
            />
            <span className="floating-label">
              Industry Type
                <span style={{ color: 'black' }}>*</span>
            </span>
          </div>
          <div className="position-relative">
            <input
              type="text"
              className="custom-input"
              name="designation"
              value={designation}
              onChange={this.changeHandler}
              id="designation"
              autocomplete="off"
              required
            />
            <span className="floating-label">
              Designation
                <span style={{ color: 'black' }}>*</span>
            </span>
          </div>
          <div className="position-relative">
            <input
              type="text"
              className="custom-input"
              name="email"
              id="email"
              value={email}
              autocomplete="off"
              onChange={this.changeHandler}
              required
            />
            <span className="floating-label">
              Official Email
                <span style={{ color: 'black' }}>*</span>
            </span>
          </div>
          <div className="position-relative">
            <input
              type="number"
              className="custom-input"
              name="phone"
              id="phone"
              value={phone}
              autocomplete="off"
              onChange={this.changeHandler}
              required
            />
            <span className="floating-label">
              Your Phone Number
                <span style={{ color: 'black' }}>*</span>
            </span>
          </div>
          <div className="py-3 pt-2">
            <button type="submit" className="btn request-button">
              Submit
              </button>
          </div>
          <div className={formSuccesMessageClass}>
            {formSuccesMessage}
          </div>
        </form> */}
      </div>
    )
  }
}
